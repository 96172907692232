import { put, select, takeEvery } from 'redux-saga/effects';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { InfluencerSearchSettings } from 'src/types';

function* getInfluencers() {
  const {
    limitElements,
    searchWord,
    currentPage,
    isInstagram,
    isTiktok,
    engagement_status,
    selectedTags,
    notInSelectedTags,
    socialPlatform,
    minFollowers,
    maxFollowers,
    statusFilter,
    selectedOrganization,
    notInSelectedOrganization,
    sort,
    selectedPool,
  }: InfluencerSearchSettings = yield select(
    (state: ReduxState) => state.influencers.influencerSearchSettings
  );

  yield put(
    actions.api.influencers.getInfluencers.started({
      limit: limitElements,
      search: searchWord,
      page: currentPage,
      isInstagram,
      isTiktok,
      engagement_status,
      selectedTags: selectedTags?.map((x) => x.id)?.toString(),
      notInSelectedTags: notInSelectedTags?.map((x) => x.id)?.toString(),
      notInSelectedOrganization:
        notInSelectedOrganization && notInSelectedOrganization.id,
      socialPlatform: socialPlatform?.toString(),
      minFollowers,
      maxFollowers,
      statusFilter,
      selectedOrganization: selectedOrganization && selectedOrganization.id,
      selectedPool: selectedPool && selectedPool.id,
      sort: JSON.stringify(sort),
    })
  );

  yield put(
    actions.api.influencers.getInfluencerCount.started({
      search: searchWord,
      isInstagram,
      isTiktok,
      engagement_status,
      selectedTags: selectedTags?.map((x) => x.id)?.toString(),
      notInSelectedTags: notInSelectedTags?.map((x) => x.id)?.toString(),
      notInSelectedOrganization:
        notInSelectedOrganization && notInSelectedOrganization.id,
      socialPlatform: socialPlatform?.toString(),
      minFollowers,
      maxFollowers,
      statusFilter,
      selectedOrganization: selectedOrganization && selectedOrganization.id,
      selectedPool: selectedPool && selectedPool.id,
    })
  );
}

// function* setCurrentInfluencers() {
//   yield put(actions.ui.influencers.setSelectedInfluencers({ influencers: [] }));
// }

export function* influencersSaga(): Generator {
  //TODO we need to get influencers on every modal hide ?
  yield takeEvery(
    [
      actions.ui.influencers.setInfluencerSearchSettings,
      actions.api.tags.assignTags.done,
      actions.api.influencers.updateInfluencer.done,
    ],
    getInfluencers
  );

  // yield takeEvery( actions.api.influencers.getOrgAssigned.done,)

  // yield takeEvery(
  //   [
  //     actions.api.influencers.removeInfluencerFromSequence.done,
  //     actions.api.influencers.removeInfluencerFromCampaign.done,
  //   ],
  //   getInfluencers
  // );
}
