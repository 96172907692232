import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import {
  // ColumnsSetting,
  Influencer,
  InfluencerOrgMap,
  InfluencerSearchSettings,
  Pool,
} from 'src/types';
import {
  getCurrentInfluencersId,
  getNormalizedInfluencers,
} from 'src/utils/reducer';

// import {
//   getCurrentInfluencersId,
//   getNormalizedInfluencers,
//   replaceElementInArray,
// } from 'src/utils/reducer';

export type State = {
  //columnsSetting: ColumnsSetting;
  data?: Influencer[];
  assignedOrg?: Array<{
    orgId: string;
    orgName: string;
    influencerId: string;
    emailSendCount: number;
    influencerOrgMap?: InfluencerOrgMap;
  }>;
  totalInfluencers?: number;
  selectedInfluencers?: string[];
  influencerSearchSettings: InfluencerSearchSettings;
  assignedPools?: Array<Pool>;
};

const initialState: State = {
  //columnsSetting: [],
  data: undefined,
  totalInfluencers: undefined,
  selectedInfluencers: undefined,
  assignedOrg: [],
  influencerSearchSettings: {
    searchWord: undefined,
    limitElements: 50,
    currentPage: 1,
    isTiktok: false,
    isInstagram: false,
    engagement_status: 'ALL',
    selectedTags: [],
    notInSelectedTags: [],
    socialPlatform: [],
    minFollowers: undefined,
    maxFollowers: undefined,
    selectedFollowerTag: undefined,
    selectedOrganization: undefined,
    notInSelectedOrganization: undefined,
    statusFilter: undefined,
  },
};

const reducer = reducerWithInitialState<State>(initialState)
  .cases(
    [actions.api.influencers.getInfluencers.done],
    (state, payload): State => ({
      ...state,
      //data: payload.result.result,
      data: getNormalizedInfluencers(payload.result.result),
      totalInfluencers: payload.result.count,
    })
  )
  // .cases(
  //   [actions.api.influencers.getInfluencerCount.done],
  //   (state, payload): State => ({
  //     ...state,
  //     totalInfluencers: payload.result.count,
  //   })
  // )
  // .cases(
  //   [actions.api.influencers.updateInfluencer.done],
  //   (state, payload): State => ({
  //     ...state,
  //     data: replaceElementInArray(payload.result, state.data),
  //   })
  // )
  .cases(
    [actions.ui.influencers.setInfluencerSearchSettings],
    (state, payload): State => ({
      ...state,
      influencerSearchSettings: {
        ...state.influencerSearchSettings,
        ...payload,
      },
    })
  )
  .cases(
    [actions.api.influencers.getOrgAssigned.done],
    (state, payload): State => {
      return {
        ...state,
        assignedOrg: payload.result,
        //columnsSetting: { ...state.columnsSetting, ...payload },
      };
    }
  )
  .cases(
    [
      actions.api.influencers.removeInfluencerFromOrganization.done,
      actions.api.influencers.switchInfluencerOrgStatus.done,
    ],
    (state, payload): State => {
      return {
        ...state,
        assignedOrg: payload.result,
        //columnsSetting: { ...state.columnsSetting, ...payload },
      };
    }
  )
  .cases(
    [actions.api.influencers.getPoolAssigned.done],
    (state, payload): State => {
      return {
        ...state,
        assignedPools: payload.result.result,
      };
    }
  )
  .cases(
    [actions.ui.influencers.setSelectedInfluencers],
    (state, payload): State => ({
      ...state,
      selectedInfluencers: getCurrentInfluencersId({
        influencers: payload.influencers,
        state: state.selectedInfluencers,
      }),
    })
  );

export const influencers = { initialState, reducer };
