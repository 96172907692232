import { ColumnGroupType, ColumnType, ColumnsType } from 'antd/es/table';

import { ColumnsSetting } from 'src/types';

interface CountryListInterface {
  [key: string]: string | undefined;
}

export const countryList: CountryListInterface = {
  US: 'US',
  CA: 'CA',
  GK: 'UK',
  SG: 'SG',
  NZ: 'NZ',
  AU: 'AU',
  '': '-',
};

export const getCountryCode = (country: string): string =>
  countryList[country] || '';

// export const getColumSettingVal = (
//   key: string,
//   columnsSetting: ColumnsSetting
// ): string => {
//   const _default = 'auto';
//   if (columnsSetting.length === 0) {
//     return _default;
//   }
//   const column = columnsSetting.find((e) => e.key === key);
//   return column?.value || _default;
// };

export const getColumSetting = (
  key: string,
  columnsSetting: ColumnsSetting
): ColumnsSetting[0] | undefined => {
  if (columnsSetting.length === 0) {
    return undefined;
  }
  const singleColumn = columnsSetting.find((e) => e.key === key);
  if (singleColumn) {
    return singleColumn;
  }
  return undefined;
};

export const isColumnVisible = (visible: string): boolean => {
  if (visible === 'hide') return false;
  return true;
};
// type NewType = ColumnsType;

// type columnsListType<T> = T & NewType;
export const filterColumns = <T>({
  columns,
  columnSettings,
}: {
  columns: ColumnsType<T>;
  columnSettings: ColumnsSetting;
}): { columnsList: ColumnsType<T>; columnSettings: ColumnsSetting } => {
  const _default = 'auto';
  const columnsList: ColumnsType<T> = [];
  const updateColumnsSetting: ColumnsSetting = [];
  const _columns = columns;
  _columns.forEach((column: ColumnGroupType<T> | ColumnType<T>) => {
    if (column.key) {
      const key = column.key as string;
      let columnSettingValue = getColumSetting(key, columnSettings);
      if (columnSettingValue === undefined) {
        columnSettingValue = {
          label: column.title as string,
          key: column.key as string,
          value: _default,
        };
      }
      updateColumnsSetting.push(columnSettingValue);
      if (isColumnVisible(columnSettingValue.value)) {
        columnsList.push(column);
      }
    }
  });
  return { columnsList, columnSettings: updateColumnsSetting };
};

export const toFirstLetterUpperCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const transformEmail = (email: string): string => {
  const [localPart, domain] = email.split('@');
  const maskedLocalPart =
    localPart.slice(0, 2) + '•••••.' + domain.split('.').pop();
  return `${maskedLocalPart}`;
};
